import React from "react"
import { navigate } from "@reach/router";

class IOSRouter extends React.Component {
  
  componentDidMount(){
    const {location} = this.props

    if(location.state.returnHomepageIOS !== undefined){
      navigate('/')
    }

  }

  render() {

    
    return ( 
      <>
      <section className="loaderCenter">Lige et øjeblik...</section>
    </>
  )}

}

export default IOSRouter

